.plans-list-width {
    width: 15rem;
}
.plans-list-container {
    width: 15.6rem;
}
.selected-plan {
    background: #222122;
}
.underline {
    text-decoration: underline;
    padding-bottom: 0.03rem;
}
.suggest-create-team {
    outline: none;
    border: none;
    background: none;
    
}
