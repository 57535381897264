.rosters-container {
    width: 25rem;    
}
.roster-width {
    width: 15rem;
}
.members-width {
    width: 5.5rem;
}
.open-roster {
    background-color: #222122;
    border-radius: 0.25rem;
    border: none;
    outline: none;
}
.roster-list-container {
    width: 15rem;
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    cursor: pointer; /* Change cursor to pointer */
  }
  
  .roster-list-container:hover {
    background-color: rgba(255,255,255, 0.05); /* Change background color on hover */
  }
  
  .roster-list-container:active {
    background-color: rgba(255,255,255, 0.1); /* Add a slight downward shift on click */
  }
.members-container {
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    cursor: pointer; /* Change cursor to pointer */
}
.members-container:hover {
    background-color: rgba(255,255,255, 0.05); /* Change background color on hover */
}
.members-container:active {
    background-color: rgba(255,255,255, 0.05); /* Change background color on hover */
}