.workout-name-input {
    background: none;
}

.workout-description {
    margin-top: 1rem;
    width: auto;
    height: 3rem;
    border-radius: 0.375rem;
    background: #222122;
    text-align: top left;
    padding: 0.25rem;
    resize: none;
  }
.workout-creation-container {
    width: 50%;
}
.workout-metrics-container {
    width: 50%;
}
.metric-input {
    width: 1.7rem;
    border-radius: 0.625rem;
    background: #2A2929;
    padding: 0.25rem;
}
.metric-input-button {
    width: 2rem;
    border-radius: 0.625rem;
    background: #2A2929;
    padding: 0.25rem;
    transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.metric-input-button:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Lighter color on hover */
}

.metric-input-button:active {
    background-color: rgba(255, 255, 255, 0.3); /* Even lighter color on click */
}
.add-modifier-button {
    border-radius: 3.5625rem;
    border: 2px solid #8A8A8A;
    background: none;
    padding: 0.2rem 0.5rem; /* Consolidated padding property */
    transition: background-color 0.3s ease, border-color 0.3s ease; 
}

/* Hover state */
.add-modifier-button:hover {
    background-color: rgba(138, 138, 138, 0.1); 
    border-color: #4D4D4D; 
}

/* Active state (click) */
.add-modifier-button:active {
    background-color: rgba(138, 138, 138, 0.2); 
    border-color: #2A2A2A; 
}

/* Selected state */
.selected-add-modifier-button {
    border-radius: 3.5625rem;
    border: 2px solid #1CDF5E;
    background: rgba(0, 255, 71, 0.33);
    padding: 0.2rem 0.5rem; /* Consolidated padding property */
    transition: background-color 0.3s ease, border-color 0.3s ease; 

}

/* Hover state for selected button */
.selected-add-modifier-button:hover {
    background-color: rgba(0, 255, 71, 0.5); 
}
.segment-type-change {
    border: none;
    width: 7rem; /* Adjust the width as needed */
    border-radius: 0.375rem;
    background-color: #222122;
    line-height: 1.4;
    font-weight: 500;
    cursor: pointer;
  }
.segment-type-change::after {
    border: none;
    outline: none;
}
.modifier-container {
    margin-top: -0.5rem;
}
.first-vertical-stem {
    border-left: 0.1rem solid #8A8A8A;
    border-bottom: 0.1rem solid #8A8A8A;
    border-bottom-left-radius: 0.5rem;
    height: 1rem;  
    width: 1rem;
}
.second-connector-stem {
    height: 2rem;
    width: 0.1rem;
    border-left: 0.1rem solid #8A8A8A;
    margin-left: 2.25rem;
    margin-top: -1.5rem;
    margin-bottom: -1.25rem
}
.placeholder-metric {
    height: 1rem;
}
.second-vertical-stem {
    padding-top: -2rem;
    border-left: 0.1rem solid #8A8A8A;
    border-bottom: 0.1rem solid #8A8A8A;
    border-bottom-left-radius: 0.5rem;
    height: 1rem;  
    width: 1rem;
}
.text-description {
    width: auto;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #aaa #f4f4f4; /* For Firefox */
}
.text-description::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
 }
 
 .text-description::-webkit-scrollbar-thumb {
    background-color: #aaa; /* Color of the scrollbar thumb */
    border-radius: 6px; /* Radius of the scrollbar thumb */
 }
 .create-workout-button {
    background: #2A2929;
    height: 2rem;
    border-radius: 1rem;
    transition: background-color 0.3s ease, color 0.3s ease; /* Add smooth transitions */
  }
  
  .create-workout-button:hover {
    background-color: rgba(255, 255, 255, 0.05); /* Hover background color */
  }
  
  .create-workout-button:active {
    background-color: rgba(255, 255, 255, 0.2); /* Active background color */
  }