.draft-new-event-button {
    border: 0.1rem solid #292928;
    background: #222122;
    border-radius: 0.25rem;
}
.first-week-container {
    overflow-x: scroll;
}
.add-training-weekly {
    background: #222122;
    height: 3rem;
    width: 11.5rem;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease;
}

.add-training-weekly:hover {
    background-color: #333332;
}

.add-training-weekly:active {
    background-color: #111111;
}

.weekly-day-container {
    background: #222122;
    border-radius: 0.5rem;
    width: 11rem;
    height: auto;
    border-radius: 0.5rem;
}