.calendar-container {
    width: 100%;
    /* height: 75vh;
    overflow-y: scroll; */
}
.calendar-container::-webkit-scrollbar-track {
    background: #161414 !important; /* Color of the scrollbar track */
}

/* Handle */
.calendar-container::-webkit-scrollbar-thumb {
    border-radius: 0.25rem !important;
    background: #333333 !important; /* Color of the scrollbar handle */
}

/* Handle on hover */
.calendar-container::-webkit-scrollbar-thumb:hover {
    background: #8A8A8A !important; /* Color of the scrollbar handle on hover */
}
.monthly-calendar-container {
    height: 91vh;
    overflow-y: hidden;
}
/* @import url('./../../ScrollBar.css'); */
.specific-day-container {
    border: 1px solid #292929;
    width: 7.08rem;
    height: 9rem;
    transition: background-color 0.3s ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    
}
.specific-day-container::-webkit-scrollbar {
    display: none;
}
.events-container {
    width: 95%;
    margin: auto;
}
.specific-day-title {
    text-wrap: wrap;
    background-color: rgb(60, 57, 55);
    height: auto;
    
}
.top-right{
    position: absolute;
    top: 0;
    right: 0
}
.day-of-week {
    width: 7rem; 
}
.day-number-padding {
    margin: 0.25rem;
}
.selected-day {
    background-color: #3D3F42;
}
.hovered-day {
    background-color: #3D3F42;
}
.selected-indicator {
    background-color: #FFD600; /* Yellow color for the indicator */
    border-radius: 50%;
    height: 1.25rem;
    width: 1.25rem;
  }