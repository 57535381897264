.settings-button {
    padding-left: 10px;
    color: #FFF;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.01313rem;
    border: none;
    outline: none;
    background: none;
    cursor: pointer; /* Add this to indicate that it's clickable */
    transition: background-color 0.3s ease, color 0.3s ease; /* Add smooth transitions */
  }
  
  .settings-button:hover {
    color: rgba(255, 255, 255, 0.8)
  }
  
  .settings-button:active {

    color: rgba(255, 255, 255, 0.6); /* Example active text color */
  }
  
.profile-component {
  padding-left: 8px;
}
.profile-button {
  width: 91%;
  border-radius: 2rem;
}

.nav-buttons {
  width: 90%;
  margin: auto;
  padding-left: 5px;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease, color 0.3s ease; /* Add smooth transitions */
}
.nav-buttons:hover {
  background-color: rgba(255, 255, 255, 0.05); /* Example hover background color */
}

.nav-buttons:active {
  background-color: rgba(255, 255, 255, 0.2); /* Example active background color */
}
.nav-text {
  padding-left: 5px;
  float: left;
}
