.plan-calendar-container {
    filter: blur(4px); /* Adjust the blur radius as needed */

}
.plan-buttons-container {
    height: auto;
}
.new-plan-button {
    border-radius: 0.5rem;
    background: #222122;
    padding: 0.5rem;
    transition: background-color 0.3s ease; /* Add smooth transitions */
    height: 2.5rem;
}
.new-plan-button-placeholder {
    border-radius: 0.5rem;
    background: #D9D9D9;
    padding: 0.5rem;
    transition: background-color 0.3s ease; /* Add smooth transitions */
    height: 2.5rem;
}

.new-plan-button:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Example hover background color */
}

.new-plan-button:active {
    background-color: rgba(255, 255, 255, 0.2); /* Example active background color */
}

.plan-description {
    margin-top: 1rem;
    width: auto;
    height: 2rem;
    border-radius: 0.375rem;
    text-align: top left;
    padding: 0.25rem;
    resize: none;
  }
.switch-plan-container {
    border-radius: 0.5rem;
    background: #222122;
    width: 10rem;
    padding: 0.5rem;
    cursor: pointer;
}

.switch-plan-container:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Example hover background color */
    transition: background-color 0.3s ease; /* Add smooth transition for hover effect */
}

.switch-plan-container:active {
    background-color: rgba(255, 255, 255, 0.2); /* Example active background color */
    transition: background-color 0.3s ease; /* Add smooth transition for active effect */
}


.new-training-button {
    border-radius: 0.375rem;
    background: #1B63DA;
    padding: 0.4rem;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Add smooth transitions */
}
.new-training-grey {
    border-radius: 0.375rem;
    background: #D9D9D9;
    padding: 0.4rem;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Add smooth transitions */
}

.new-training-button:hover {
    background-color: #2A85FF; /* Brighter shade for hover effect */
}

.new-training-button:active {
    transform: scale(0.95); /* Slight scale down for click effect */
    background-color: #0D2B4D; /* Darker shade for click effect */
}
.plans-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    border: none;
    width: auto; /* Adjust the width as needed */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.375rem;
    background-color: #222122;
    color: #FFF;
    line-height: 1.4;
    font-weight: 500;
    cursor: pointer;
  }
.plan-date-selector-container {
    background-color: #222122;
    color: #FFF;
    border-radius: 0.375rem;
    width: auto;
    height: 2.12rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.plan-date-input {
    width: auto;
    border: none;
    outline: none; 
    background-color: #222122;
    color: #FFF;
    border-radius: 0.375rem;
    font-weight: 500;
  }
.plan-date-input::-webkit-calendar-picker-indicator {
    filter: invert(1); /* Invert the colors, making it white */
    cursor: pointer;
}

/* Make the calendar icon white in Firefox */
.plan-date-input[type="date"]::-webkit-inner-spin-button {
    filter: invert(1); /* Invert the colors, making it white */
    cursor: pointer;
}
.training-day-title {
    text-wrap: wrap;
    background-color: rgb(60, 57, 55);
    height: auto;
    border-radius: 0.25;
}
.select-plan-roster {
    max-height: 15rem;
    overflow-y: auto;
}
.roster-select {
    width: 1.7rem;
    height: 1rem;
    border: 1px solid white;
    border-radius: 1rem;
    cursor: pointer;
}
.roster-select-selected {
    width: 1.7rem;
    height: 1rem;
    border: 1px solid white;
    border-radius: 1rem;
    cursor: pointer;
    background-color: #1d91f6;
}