
.new-event-button {
    border: 0.1rem solid #292928;
    background: #222122;
    border-radius: 0.25rem;
}
.grey-event-button {
  width: 7.125rem;
  height: 2rem;
  border-radius: 0.375rem;
  background-color: #D9D9D9;
  cursor: pointer;
}
  .selector-button {
    padding-bottom: 1.2rem;
    padding-left: .5rem;
    padding-right: 0.5rem;
  }
  .left-button {
    transform: scaleX(-1)
  }
  .day-button {
    border-radius: 0.3125rem;
    background: #292929;
    height: 1.4rem;
    border: none; /* Remove default button border */
    transition: background 0.3s; /* Add smooth transition for background color */
    cursor: pointer;
  
    /* Hover effect */
    &:hover {
      background: #404040;
    }
  
    /* Click effect */
    &:active {
      background: #1f1f1f;
    }
  }

.date-selector-container {
  background-color: #222122;
  color: #FFF;
  border-radius: 0.375rem;
  width: 7.625rem;
  height: 1.4375rem;
}
.date-input {
  width: 7.625rem;
  height: 1.4375rem;
  border: none;
  outline: none; 
  background-color: #222122;
  color: #FFF;
  border-radius: 0.375rem;
  font-size: 1rem;  
  font-weight: 500;
}
/* Make the calendar icon white in Chrome and Safari */
.date-input::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Invert the colors, making it white */
  cursor: pointer;
}

/* Make the calendar icon white in Firefox */
.date-input[type="date"]::-webkit-inner-spin-button {
  filter: invert(1); /* Invert the colors, making it white */
  cursor: pointer;
}
.time-change-input {
  border: none;
  outline: none;
  width: 1.2rem;
  border-radius: 0.375rem;
  background-color: #222122;
  color: #FFF;
  border-radius: 0.375rem;
  padding-right: 0px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}
.hours-input {
  padding-right: -2px;
}
.am-pm-selector {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  border: none;
  width: 1.6rem; /* Adjust the width as needed */
  border-radius: 0.375rem;
  background-color: #222122;
  color: #FFF;
  line-height: 1.4;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
}

.am-pm-selector::-ms-expand {
  display: none;
}

.am-pm-selector::after {
  content: '▼'; /* Unicode character for down arrow or your custom arrow character */
  color: #FFF;
  position: absolute;
  top: 50%;
  right: 8px; /* Adjust the distance from the right edge */
  transform: translateY(-50%);
}

.event-repeat {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  border: none;
  width: 5rem; /* Adjust the width as needed */
  border-radius: 0.375rem;
  background-color: #222122;
  color: #FFF;
  line-height: 1.4;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
}

.event-repeat::-ms-expand {
  display: none;
}

.event-repeat::after {
  content: '▼'; /* Unicode character for down arrow or your custom arrow character */
  color: #FFF;
  position: absolute;
  top: 50%;
  right: 8px; /* Adjust the distance from the right edge */
  transform: translateY(-50%);
}
.switch-linked-team {
  background: #222122;
}
.description-input {
  margin-top: 1rem;
  width: auto;
  height: 4.875rem;
  border-radius: 0.375rem;
  background: #222122;
  text-align: top left;
  padding: 0.25rem;
  resize: none;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #aaa #f4f4f4; /* For Firefox */
}

.description-input::-webkit-scrollbar {
   width: 12px; /* Width of the scrollbar */
}

.description-input::-webkit-scrollbar-thumb {
   background-color: #aaa; /* Color of the scrollbar thumb */
   border-radius: 6px; /* Radius of the scrollbar thumb */
}

.workout::-webkit-scrollbar-track {
   background-color: #f4f4f4; /* Color of the scrollbar track */
}

.create-event-button {
  border-radius: 0.25rem;
  border: 1px solid #8A8A8A;
  height: 2.6875rem;
  background: none;
  border-radius: 0.25rem;
  border: 1px solid #8A8A8A;
  outline: none;
  padding-left: 1rem;
  padding-right: 1rem;
}
.create-button {
  background: #2A2929;
  width: 25rem;
  height: 2rem;
  border-radius: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease; /* Add smooth transitions */
}

.create-button:hover {
  background-color: rgba(255, 255, 255, 0.05); /* Hover background color */
}

.create-button:active {
  background-color: rgba(255, 255, 255, 0.2); /* Active background color */
}
.switch-team-select {
  background-color: #222122;
  border: none;
  outline: none;
  border-radius: 0.75rem;
}
.switch-team-select option {
  text-align: center;
}