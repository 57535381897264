.blurred {
    filter: blur(5px); /* Apply blur to the background when the modal is open */
}
.join-team-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #161414;
    border: 1px solid #ccc;
    padding: 20px;
    width: 48.0625rem;
    height: auto;
    overflow: auto;
    outline: none; /* Remove default focus outline */
    border: none;
  }
  
  /* Styles for the overlay */
  .join-team-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
.enter-join-code {
    width: 70%;
    border-radius: 0.25rem;
    background: #222122;
    padding-left: 0.3rem;
}
.join-team-button {
    border-radius: 0.25rem;
    background: #222122;
    width: 30%;
    height: 2.1875rem;
}
.join-team-message {
    color: #FFF;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.01219rem;
}
.create-team-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #161414;
    border: 1px solid #ccc;
    padding: 20px;
    width: 48.0625rem;
    height: auto;
    overflow: auto;
    outline: none; /* Remove default focus outline */
    border: none;
  }
  
  /* Styles for the overlay */
  .create-team-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
.team-create-input-container {
    width: 70%;
}
.team-create-input {
    height: 2.1875rem;
    border-radius: 0.25rem;
    background: #222122;
    padding-left: 0.3rem;
}
.team-create-button {
    height: auto;
    border-radius: 0.25rem;
    background: #222122;
    padding-left: 0.3rem;
    width: 30%;
    transition: background-color 0.3s ease, color 0.3s ease; /* Add smooth transitions */

}
.team-create-button:hover {
    background-color: rgba(255, 255, 255, 0.05); /* Example hover background color */
}

.team-create-button:active {
    background-color: rgba(255, 255, 255, 0.1); /* Example active background color */
}

.tri-club-text {
    background: linear-gradient(268deg, #1BC3DA -4.71%, #1B63DA 110.87%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-right: 3rem;
}
.summary-text {
    background: linear-gradient(268deg, #FFD600 -10.52%, #D9D9D9 110.7%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.team-summary-box {
    border-radius: 0.25rem;
    background: #222122;
    padding: 0.25rem;
}
.white-icon-container img {
    fill: white;
}
.roster-container {
    border-radius: 0.5rem;
    background: #222122;
    padding: 0.5rem;
}