.lift-card-container {
    width: 13.25rem;
    border-radius: 0.625rem;
    background: #2A2929;
    padding: 0.25rem;
}
.movement-name-input {
    background: none
}
.lift-border-line {
    background-color: #8A8A8A;
    height: 0.09rem;
    width: 100%;
    margin: auto
}
.no-lifting-modifiers {
    margin-left: 3rem;
}
.lift-container {
    min-height: 10.25rem;
}
.pace-container {
    background-color: #2A2929;
    border-radius: 0.625rem;
}
.pace-input {
    width: 1rem;
    border: none;
    outline: none;
    background-color: #2A2929;
    border-radius: 0.25rem;
    padding: 0.1rem;
}
.top-layer {
    z-index: 1000;
}