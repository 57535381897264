.training-manager-container {
    overflow-x: hidden;
}
.training-calendar-container {
    width: 56rem; 
}
.training-manager-monthly-container {
    width: 100%;
    height: 88vh;
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  /* Style the scrollbar */
  .training-manager-monthly-container::-webkit-scrollbar {
   display: none;
  }

.weekly-training-container {
    width: 100%;
}