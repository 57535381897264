/* custom-scrollbar.css */
::-webkit-scrollbar {
    width: 0.25rem; /* Width of the scrollbar */
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #161414; /* Color of the scrollbar track */
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background: #333333; /* Color of the scrollbar handle */
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8A8A8A; /* Color of the scrollbar handle on hover */
  }
  