/* custom-checkbox.css */
/* Hide the default checkbox */
input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
}

/* Style the custom checkbox */
.custom-checkbox {
  width: 1rem;
  height: 1rem;
  border: 0.5px solid #ccc;
  display: inline-block;
  background-color: none;
}

/* Style the custom checkbox when checked */
input[type="checkbox"]:checked + .custom-checkbox {
  background-color: #1B7FD9;/* Change color when checked */
}

/* Increase specificity to ensure styles are applied */
div .custom-checkbox {
  width: 1rem;
  height: 1rem;
  border: 0.1rem solid #ccc;
  display: inline-block;
  background-color: none;
}

div input[type="checkbox"]:checked + .custom-checkbox {
  background-color: #1B7FD9; /* Change color when checked */
}
.purple-box {
  width: 3rem;
  height: 3rem;
  background-color: rgb(111, 96, 187);
  border-radius: 1rem;
}
.chat-name {
  padding-top: 0.5rem;
}
.message-bar-container {
  position: fixed;
  bottom: 10px;
  background-color: #1C1C1C;
  border-radius: 0.5rem;
  width: 60%;
  right: 4.5%;
}
.announcement-bar-container {
  background-color: #1C1C1C;
  border-radius: 0.5rem;
  width: 70%;
  margin: auto;
}
.announcement-blur-container {
  position: fixed;
  bottom: 0; 
  right: 0;
  width: 86%;
  height: 17%; /* Set the height to cover the entire viewport */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.05)); /* Adjust the alpha values and stops as needed */
  backdrop-filter: blur(10px); /* Adjust the blur radius as needed */
}
.announcement-card-container {
  background-color: #1C1C1C;
  border-radius: 0.5rem;
  width: 80%
}
.reply-container {
  background-color: #1C1C1C;
  border-radius: 0.5rem;
  width: 60%
}
.message-text-area {
  width: 100%;
  padding-top: 0.5rem;
  box-sizing: border-box;
}
.container {
  width: 100%;
}
.submit-arrow-container {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  background-color: #136cc5;
}
.announcement-submit-container {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  background-color: #399d29;
}
.profile-placeholder {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: rgb(255, 67, 67);
}
.message-container {
  width: 80%
}
.new-message-circle {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: #1CDF5E;
}
.announcement-border-line {
  border-radius: 0.625rem;
  background: #8A8A8A;
  height: 0.1rem;
}
.bottom-border-line {
  width: 98%;
  margin-left: 1%;
}
.announcement-title-input {
  width: 100%;
}
.comments-button {
  width: fit-content;
  padding: 0.7rem;
  border-radius: 0.7rem
}
.comment-feed-container {
  height: 80vh;
  margin-bottom: 17vh;
  overflow-y: auto;
}
