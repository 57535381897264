.nav-bar-container {
    height: 4rem;
}
.logged-out-nav-button {
    background-color: #222122;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 3rem;
}
.home-nav-container {
    margin-left: 2.5rem;
}
.auth-buttons-container {
    margin-right: 5rem;
}