html {
    transform: scale(0.9);
    transform-origin: top left;
    width: 111.11%; 
    height: 111.11%; 
}
.App {
    text-align: center;
    margin: 0;
}
body {
    padding: 0%;
    margin: 0%;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #161614;
    overflow-x: hidden;
    overflow-y: hidden;
}
button {
    cursor: pointer;
}
textarea {
    resize: none;
    background: none;
    outline: none;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    height: auto;
}
input[type="checkbox"] {
    width: 1em;
    height: 1rem;
    accent-color: yellow;
  }  
  
.workout-icon {
    fill: white;
}
.medium {
    font-size: 2rem;
    letter-spacing: -0.03rem;
}
.small-medium {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.03rem;
}
.mid-text {
    font-size: 1.5rem;
}
.shmedium {
    font-size: 1.4rem;
}
.small-header {
    font-size: 1.3rem;
}
.small {
    font-size: 1.1rem;
}
.very-small {
    font-size: 1rem;
}
.really-small {
    font-size: 0.8rem;
    letter-spacing: 0.01rem
}
.super-small {
    font-size: 0.75rem
}
.plus-grey {
    color: #333;
}
.white {
    color: #FFF;
}
.mid-grey {
    color: #404040;
}
.light-grey {
    color: #8A8A8A;
}
.slate-grey {
    color: #1C1C1C;
}
.background-grey {
    color: #161414;
}
.submit-green {
    color: #1CDF5E; 
}
.run-green {
    background: linear-gradient(110deg, #1CDF5E 36.63%, #00FF47 101.14%);
}
.distance-red {
    color: #E82626;
}
.heart-rate-pink {
    color: #E45792;
}
.duration-yellow {
    color: #D9E833;
}
.calorie-orange {
    color: #FF6B00
}
.pace-blue {
    color: #33E8DD;
}
.delete-red {
    color: #df3907;    
}
.ocean-blue {
    color: #1d91f6;
}
.update-purple {
    color: #BF5AF2;
}
.light-weight {
    font-weight: 400;
}
.fly-weight {
    font-weight: 550;
}
.mid-weight {
    font-weight: 600;
}
.bold {
    font-weight: 700;
}
.pointer {
    cursor: pointer;
}
.outline-button {
    border: 0.5px solid #8A8A8A;
    border-radius: 0.375rem;
    background: none;
    outline: none;
    height: 1.7rem;
    transition: background-color 0.3s ease, color 0.3s ease; /* Add smooth transitions */
    cursor: pointer;
}
.outline-button:hover {
    background-color: rgba(255, 255, 255, 0.05); /* Example hover background color */
}

.outline-button:active {
    background-color: rgba(255, 255, 255, 0.2); /* Example active background color */
}
.icon-button {
    width: fit-content;
    background: none;
    border: none;
    cursor: pointer; /* Add this to indicate that it's clickable */
    transition: background-color 0.3s ease, color 0.3s ease; /* Add smooth transitions */
}

.icon-button:hover {
    background-color: rgba(255, 255, 255, 0.05); /* Example hover background color */
}

.icon-button:active {
    background-color: rgba(255, 255, 255, 0.2); /* Example active background color */
}
.text-button {
    background: none;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    height: 1.3rem;
    width: fit-content;
    padding: 3px;
    border-radius: 3px;
    transition: background-color 0.3s ease, color 0.3s ease; /* Add smooth transitions */

}
.text-button:hover {
    background-color: rgba(255, 255, 255, 0.05); /* Example hover background color */
}

.text-button:active {
    background-color: rgba(255, 255, 255, 0.2); /* Example active background color */
}
.border-outline {
    border: none;
    outline: none;
}
.background-none {
    background: none;
}
.page-border-line {
    border-radius: 0.625rem;
    background: #222122;
    width: auto;
    height: 0.125rem;
}
.page-border-line-white {
    border-radius: 0.625rem;
    background: white;
    width: auto;
    height: 0.05rem;
}
.vertical-border {
    border-radius: 0.625rem;
    background: #222122;
    width: 0.125rem;
    height: auto;
}
.full-vertical-border {
    border-radius: 0.625rem;
    background: #222122;
    width: 0.125rem;
    height: 111.5vh;
}
.selected-tab {
    border-radius: 0.25rem;
    background: #4A4A4A;
    padding: 0.25rem;
}
.team-selected-tab {
    border-radius: 1.5rem;
    background: #4A4A4A;
    padding: 0.25rem;
}
.more-padding-left {
    padding-left: 0.6rem;
}
.more-padding-right {
    padding-right: 0.6rem;
}
.padding {
    padding: 0.25rem;
}
.mid-padding {
    padding: 0.4rem;
}
.more-padding {
    padding: 0.6rem;
}
.twice-more-padding {
    padding: 1.2rem;
}
.padding-left {
    padding-left: 0.25rem;
}
.padding-right {
    padding-right: 0.25rem;
}
.padding-top {
    padding-top: 0.5rem;
}
.more-padding-bottom {
    padding-bottom: 0.5rem;
}
.less-padding-top {
    padding-top: 0.25rem;
}
.padding-bottom {
    padding-bottom: 0.25rem
}
.tons-padding-bottom {
    padding-bottom: 2rem;
}
.margin {
    margin: 0.25rem;
}
.margin-top {
    margin-top: 1rem;
}
.margin-bottom {
    margin-bottom: 0.25rem;
}
.more-margin {
    margin: 0.5rem;
}
.margin-right {
    margin-right: 0.25rem;
}
.more-margin-right {
    margin-right: 0.6rem;
}
.more-margin-left {
    margin-left: 0.6rem;
}
.twice-more-margin {
    margin-left: 1.2rem;
}
.main-content-margin {
    padding-top: 1.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.fixxed {
    position: fixed;
}
.leftNavBar {
    position: fixed;
    width: 12.5rem;
    background-color: #1A1818;
    height: 111.5vh;
  }
.main-content {
    width: 100%;
    margin-left: 12.5rem;
}
.team-container {
    border: 0.1rem solid #BF5AF2;
    min-width: 8rem;
    border-radius: 0.6rem;
    padding-right: 1rem;
    padding-left: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.team-tag {
    width: fit-content;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 0.1rem solid #e8262689;
    border-radius: 0.5rem;
}
.plan-tag {
    width: fit-content;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 0.1rem solid #1cdf5db1; 
    border-radius: 0.5rem;
}
.personal-tag {
    width: fit-content;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 0.1rem solid #1d91f68d; 
    border-radius: 0.5rem;
}