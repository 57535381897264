.workout-type-select {
    background: none;
    border-radius: 0.875rem;
    border: 1px solid #8A8A8A;
}
select option {
    background-color: #2A2929; /* Background color for unselected options */
    color: #fff; /* Text color for unselected options */
  }
.workout-snapshot-container {
    border-radius: 0.5625rem;
    background: #1C1C1C;
    width: 100%;
    height: 3.625rem;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, color 0.3s ease; /* Add smooth transitions */
}

.workout-snapshot-container:hover {
    background-color: rgba(255, 255, 255, 0.05); /* Hover background color */
}

.workout-snapshot-container:active {
    background-color: rgba(255, 255, 255, 0.2); /* Active background color */
}


.workout-snapshot-content {
    padding-left: 1rem;
    padding-right: 1rem;   
    width: 100%;
}