.icon-size {
    width: 1rem;
    height: auto;
}
.height-icon {
    height: 1rem;
    width: auto;
}
.medium-icon {
    width: 1.15rem;
    height: auto;
}
.big-icon {
    width: 1.3rem;
    height: auto;
}
.small-icon {
    width: 0.7rem;
    height: auto;
}