.top-event-snippet-container {
    width: 10rem;
    height: 1.25rem;   
    border-radius: 0.375rem 0.375rem 0.04375rem 0rem;
    background: linear-gradient(265deg, #FFFFFD -27.58%, #00F0FF 86.33%);
}
.time-container {
    padding-top: 0.2rem;
    padding-left: 0.2rem;
}
.bottom-event-snippet-container {
    width: 10rem;
    min-height: 3.4rem;
    height:auto;
    border-radius: 0rem 0rem 0.40938rem 0.57813rem;
    border-right: 0.5px solid #8A8A8A;
    border-bottom: 0.5px solid #8A8A8A;
    border-left: 0.5px solid #8A8A8A;
}
.event-location-name {
    padding-top: 0.2rem;
    padding-left: 0.2rem;
    width: 8rem;
    height: auto; /* Set height to auto for automatic vertical expansion */
}
.location {
    white-space: pre-wrap;
    color: #808080;
}