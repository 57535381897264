.team-component {
    border-radius: 0.375rem;
    background: rgba(34, 33, 34, 0.27);
    padding: 0.25rem;
}
.training-plan-name {
    background: linear-gradient(268deg, #1BC3DA -4.71%, #1B63DA 110.87%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
}
.training-plan-link {
    padding: 3px;
    height:2.2rem;
}
.team-component-border-line {
    border-radius: 0.5625rem;
    background: #D9D9D9;
    width: 0.0625rem;
    height: 2.2rem;
}
/* Add this to your CSS file or style block */
.calendar-container {
    padding-bottom: 1rem;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #8A8A8A #404040; /* For Firefox */
    
    /* For WebKit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
        height: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #8A8A8A;
        border-radius: 0.25rem;
    }

    &::-webkit-scrollbar-track {
        background-color: #404040;
        border-radius: 0.25rem;
    }
}
/* Add a hover effect to the scrollbar thumb */
.calendar-container:hover::-webkit-scrollbar-thumb {
    background-color: #555;
}
.day-snippet {
    width: 10rem;
    display: inline-block;
}
.event-box {
    padding-bottom: 1rem;
}
.shortcut-button {
    background-color: #292929;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease, color 0.3s ease; /* Add smooth transitions */
    cursor: pointer;
}

.shortcut-button:hover {
    background-color: rgba(68, 68, 68, 0.65); /* Example hover background color */
}

.shortcut-button:active {
    background-color: rgba(68, 68, 68, 0.5); /* Example active background color */
}
